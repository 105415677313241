import firebase from 'firebase';

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyDUINKpFLM14iej-KTDJeD2p54wa2CxaUI",
  authDomain: "messenger-clone-74bb4.firebaseapp.com",
  databaseURL: "https://messenger-clone-74bb4.firebaseio.com",
  projectId: "messenger-clone-74bb4",
  storageBucket: "messenger-clone-74bb4.appspot.com",
  messagingSenderId: "912957991533",
  appId: "1:912957991533:web:f761e7f5a84191f13aad64",
  measurementId: "G-BD0RKJKPS2"
});

const db = firebaseApp.firestore();

export default db;