import { Card, CardContent, Typography } from '@material-ui/core'
import React, { forwardRef } from 'react'
import '../styles/Message.css'

const Message = forwardRef(({ id, message, username }, ref) => {
  const isUser = username === message.username;
  return (
    <div ref={ref} className={`message ${isUser && 'message-user'}`} >
      <Card className={isUser ? "message-userCard" : "message-guestCard"}>
        <CardContent>
          <Typography color="white" variant="h5" component="h2">
            {!isUser && `${message.username || 'Anonymous User'}: `} {message.message}
          </Typography>
        </CardContent>
      </Card>
    </ div>
  )
})

export default Message
